document.addEventListener('DOMContentLoaded', function() {
    const sliderClick = document.querySelector('.circle-slider');
    const chats = document.querySelectorAll('.Group4'); // Assuming .Group4 represents chat elements
    const homeContainer = document.querySelector('.Rectangle1');
    const notifications = document.querySelectorAll('.notification-class'); // Assuming .notification-class is the class for notifications

    // Function to check the screen size and apply styles
    function applyStylesBasedOnScreenSize() {
        const screenWidth = window.innerWidth;
        const people = document.querySelector('.people');
        const charts = document.querySelector('.conversation-container');
        const person = document.querySelectorAll('.person-container');

        person.forEach(element => {
            element.addEventListener('click', function() {
                if (screenWidth < 850) {
                    people.classList.toggle('hidden-container');
                    charts.classList.toggle('hidden-container');
                } else {
                    people.classList.remove('hidden-container');
                    charts.classList.remove('hidden-container');
                }
            });
        });
    }

    chats.forEach(chat => {
        chat.addEventListener('click', function(e) {
            e.preventDefault();
            const URL = window.location.href;
            const categoryOne = document.querySelectorAll('.categoryOne');
            const signup = document.querySelector('.DontHaveAnAccount');
            const login = document.querySelector('.Rectangle38');
            console.log(URL);

            signup.innerHTML = "";
            login.innerHTML = "";
            if (sliderClick) sliderClick.innerHTML = "";
            categoryOne.forEach(element => {
                element.innerHTML = "";
            });

            const createLoaderDiv = document.createElement('div');
            const createElementSpan = document.createElement('span');
            homeContainer.appendChild(createLoaderDiv);
            createLoaderDiv.appendChild(createElementSpan);

            createLoaderDiv.classList.add('spinner-grow', 'position-absolute', 'top-50', 'z-3', 'start-50', 'translate-middle');
            createElementSpan.classList.add('visually-hidden');
            createLoaderDiv.setAttribute('role', 'status');

            fetch(URL + '/chat')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text(); // Assuming the response is HTML content
                })
                .then(htmlContent => {
                    login.style.display = "none";
                    homeContainer.innerHTML = htmlContent;
                    createLoaderDiv.remove();
                    createElementSpan.remove();

                    // Call the function to apply styles based on screen size
                    applyStylesBasedOnScreenSize();
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                });
        });
    });

    notifications.forEach(notification => {
        notification.addEventListener('click', function(e) {
            e.preventDefault();
            const URL = window.location.href;
            const categoryOne = document.querySelectorAll('.categoryOne');
            const signup = document.querySelector('.DontHaveAnAccount');
            const login = document.querySelector('.Rectangle38');
            console.log(URL);

            homeContainer.innerHTML = '';
            signup.innerHTML = "";
            login.innerHTML = "";
            if (sliderClick) sliderClick.innerHTML = "";
            categoryOne.forEach(element => {
                element.innerHTML = "";
            });

            const createLoaderDiv = document.createElement('div');
            const createElementSpan = document.createElement('span');
            homeContainer.appendChild(createLoaderDiv);
            createLoaderDiv.appendChild(createElementSpan);

            createLoaderDiv.classList.add('spinner-grow', 'position-absolute', 'top-50', 'z-3', 'start-50', 'translate-middle');
            createElementSpan.classList.add('visually-hidden');
            createLoaderDiv.setAttribute('role', 'status');

            fetch('https://beautopiamonsters.com/notification/')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text(); // Assuming the response is HTML content
                })
                .then(htmlContent => {
                    login.style.display = "none";
                    homeContainer.innerHTML = htmlContent;
                    createLoaderDiv.remove();
                    createElementSpan.remove();

                    // Call the function to apply styles based on screen size
                    applyStylesBasedOnScreenSize();
                })
                .catch(error => {
                    console.error('Fetch error:', error);
                });
        });
    });

    // Add event listener for window resize
    window.addEventListener('resize', applyStylesBasedOnScreenSize);

    // Call once to apply styles on initial load
    applyStylesBasedOnScreenSize();

    var rotation = 0;
    var Desktop1 = document.querySelector('.Desktop1');
    var activate = document.querySelectorAll('.img-slider');
    var activate2 = document.querySelectorAll('.img-slider1');

    var colors = [
        "linear-gradient(to left top, #33539e, #7367ab, #a17eb8, #c899c5, #e8b7d4)",
        "linear-gradient(to left top, #a567be, #927dce, #838fd6, #7b9fd8, #7facd6)",
        "linear-gradient(to left top, #e8b7d4, #dca1cc, #cc8dc6, #ba79c1, #a567be)",
        "linear-gradient(to left top, #bfb8da, #c9b8db, #d4b7da, #deb7d8, #e8b7d4)"
    ];
    var colorIndex = 0;

    var categories = [
        {
            name: "Barber Monsters",
            items: [
                "/beautopia/products/barbers/360_F_378831540_10ShB9tnvs2quli24qe53ljhvsL07gjz.jpg",
                "/beautopia/products/barbers/651.png",
                "/beautopia/products/barbers/a-barber-shop-logo-design-template-d631279a411f47f76c15980802bf8413.jpg",
                "/beautopia/products/barbers/black-barber-shop-logo.png",
                "/beautopia/products/barbers/images.png",
                "/beautopia/products/barbers/legends-barbershop-400x400.jpg",
                "/beautopia/products/barbers/4d78ebf86f5ae4db24143c8a2af603b2.jpg"
            ]
        },
        {
            name: "Salon Monsters",
            items: [
                "/beautopia/products/salon/iv7wnzxeadaa1.png",
                "/beautopia/products/salon/Creative-beauty-salon-logo-vector.jpg",
                "/beautopia/products/salon/preview-page2.jpg",
                "/beautopia/products/salon/beauty_salon.png",
                "/beautopia/products/salon/hair-logo.jpg",
                "/beautopia/products/salon/istockphoto-1027575800-612x612.jpg",
                "/beautopia/products/salon/attachment_102767932.jpg"
            ]
        },
        {
            name: "Product Monsters",
            items: [
                "/beautopia/products/product/New-Neostrata-Lotion-Plus-21.jpg",
                "/beautopia/products/product/Body-Lotion-Hyaluronic-Vanilla-Frag-500ml-copy.jpg",
                "/beautopia/products/product/images (3).jpg",
                "/beautopia/products/product/images (2).jpg",
                "/beautopia/products/product/images.jpg",
                "/beautopia/products/product/01_101922590_SI_00.jpg",
                "/beautopia/products/product/download.jpg"
            ]
        },
        {
            name: "NailTech Monsters",
            items: [
                "/beautopia/products/nail/images.jpg",
                "/beautopia/products/nail/images.png",
                "/beautopia/products/nail/desktop-wallpaper-nail-salon-logo-nails-logo-nail-technician-logo-nail-art-logo-salon-logos-nail-logo-nail-tech-business-cards-nail-stickers-designs.jpg",
                "/beautopia/products/nail/4-20-9-14-4-58-15m.jpg",
                "/beautopia/products/nail/f3ffd88b81024cd40b1650f8b364840f.jpg",
                "/beautopia/products/nail/nail-tech-logo-design-template-ed9242c82854b4b377554ef3b6db156f_screen.jpg",
                "/beautopia/products/nail/nail-tech-logo-(1)-design-template-10982053ba8b9b5f1ef10670c9d40346_screen.jpg"
            ]
        }
    ];

    var categoryNames = [
        {
            name: "Barber Monsters",
            items: [
                "Barber Shop 1",
                "Barber shop 2",
                "Barber shop 3",
                "Barber shop 4",
                "Barber shop 5",
                "Barber shop 6",
                "Barber shop 7"
            ]
        },
        {
            name: "Salon Monsters",
            items: [
                "Salon Shop 1",
                "Salon shop 2",
                "Salon shop 3",
                "Salon shop 4",
                "Salon shop 5",
                "Salon shop 6",
                "Salon shop 7"
            ]
        },
        {
            name: "Product Monsters",
            items: [
                "Product 1",
                "Product 2",
                "Product 3",
                "Product 4",
                "Product 5",
                "Product 6",
                "Product 7"
            ]
        },
        {
            name: "NailTech Monsters",
            items: [
                "NailTech Shop 1",
                "NailTech shop 2",
                "NailTech shop 3",
                "NailTech shop 4",
                "NailTech shop 5",
                "NailTech shop 6",
                "NailTech shop 7"
            ]
        }
    ];
console.log('is')
    if (sliderClick) {
    console.log('not')
        sliderClick.addEventListener('click', function() {
            rotation += 90;
            rotation %= 360; // Reset rotation to a multiple of 360 degrees
            sliderClick.style.transform = "rotate(" + rotation + "deg)";

            // Change the rotation for each image
            activate.forEach(function(item) {
                item.style.transform = "rotate(" + rotation + "deg)";
            });

            activate2.forEach(function(item) {
                item.style.transform = "rotate(" + ((rotation + 180) % 360) + "deg)";
            });

            // Change background color on each rotation
            Desktop1.style.background = colors[colorIndex];
            colorIndex = (colorIndex + 1) % colors.length;

            // Update category and items using switch-case
            var currentCategory = categories[colorIndex % categories.length];
            var currentCategoryName = categoryNames[colorIndex % categoryNames.length];
            document.querySelector('.categoryOne h1').textContent = currentCategoryName.name;
            var categoryItems = document.querySelectorAll('.categoryOne .objectImage img');

            categoryItems.forEach(function(item, index) {
                item.setAttribute('src', currentCategory.items[index % currentCategory.items.length]);
                item.classList.add("slide-left");

                // Listen for the animationend event
                item.addEventListener('animationend', function() {
                    // Remove the animation class after the animation ends
                    item.classList.remove("slide-left");
                });
            });

            var categoryNameElements = document.querySelectorAll('.categoryOne .objectName p');
            categoryNameElements.forEach(function(nameElement, index) {
                nameElement.textContent = currentCategoryName.items[index % currentCategoryName.items.length];
                nameElement.classList.add("slide-left-text");

                // Listen for the animationend event
                nameElement.addEventListener('animationend', function() {
                    // Remove the animation class after the animation ends
                    nameElement.classList.remove("slide-left-text");
                });
            });
        });
    }
});

