import React, { useEffect, useState } from 'react';
import './App.css';
import './beautopia/homepage.css';
import './beautopia/homepage.js';
import './beautopia/screen-size-checker.js';

function App() {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/beautopia/homepage.html')
      .then(response => response.text())
      .then(data => {
        setHtmlContent(data);
        // Run the scripts after the HTML content is set
        setTimeout(() => {
          // Import and execute homepage.js
          const script = document.createElement('script');
          script.src = './beautopia/homepage.js';
          script.async = true;
          document.body.appendChild(script);
          
          // Import and execute screen-size-checker.js
          const screenSizeCheckerScript = document.createElement('script');
          screenSizeCheckerScript.src = './beautopia/screen-size-checker.js';
          screenSizeCheckerScript.async = true;
          document.body.appendChild(screenSizeCheckerScript);
        }, 0);
      })
      .catch(error => console.error('Error fetching the HTML file:', error));
  }, []);

  return (
    <div className="App">
      {htmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;

