document.addEventListener('DOMContentLoaded', function() {
    var link = window.location.href;

    var screenSizeData5SE = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 320,
        minScreenHeight: 568
    };

    var screenSizeDataGalaxyS9 = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 360,
        minScreenHeight: 740
    };

    var screenSizeDataGalaxyPlusS9 = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 414,
        minScreenHeight: 896
    };

    var screenSizeDataDefault = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 375,
        minScreenHeight: 680
    };

    var screenSizeDataPixel2Xl = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 411,
        minScreenHeight: 823
    };

    var screenSizeDataPlus = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 414,
        minScreenHeight: 736
    };

    var screenSizeDataKindleFireHdx = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 800,
        minScreenHeight: 1280
    };

    var screenSizeDataMediumScreen = {
        themeDirURI: link + '/BeautopiaMonster',
        minScreenWidth: 1024,
        minScreenHeight: 800
    };

    // Check screen size and enqueue CSS file
    function checkScreenSizeAndEnqueueCSS() {
        // Get screen width and height
        var screenWidth = window.innerWidth;
        var screenHeight = window.innerHeight;
        var cssFilePath = '';

        console.log('before if statement');

        if (screenWidth <= screenSizeData5SE.minScreenWidth && screenHeight <= screenSizeData5SE.minScreenHeight) {
            // Enqueue the CSS file for iPhone 5SE screen size
            console.log('before generated link for iPhone 5SE');
            cssFilePath = screenSizeData5SE.themeDirURI + '/iPhone-5SE.css';
        } else if (screenWidth <= screenSizeDataGalaxyS9.minScreenWidth && screenHeight <= screenSizeDataGalaxyS9.minScreenHeight) {
            // Enqueue the CSS file for Galaxy S9 screen size
            console.log('before generated link for Galaxy S9');
            cssFilePath = screenSizeDataGalaxyS9.themeDirURI + '/galaxy-s9.css';
        } else if (screenWidth <= screenSizeDataPixel2Xl.minScreenWidth && screenHeight <= screenSizeDataPixel2Xl.minScreenHeight) {
            console.log('before generated link for Pixel 2 XL');
            cssFilePath = screenSizeDataPixel2Xl.themeDirURI + '/pixel-2-xl.css';
        } else if (screenWidth <= screenSizeDataPlus.minScreenWidth && screenHeight <= screenSizeDataPlus.minScreenHeight) {
            console.log('before generated link for iPhone 8 Plus');
            cssFilePath = screenSizeDataPlus.themeDirURI + '/iphone-8-plus.css';
        } else if (screenWidth <= screenSizeDataGalaxyPlusS9.minScreenWidth && screenHeight <= screenSizeDataGalaxyPlusS9.minScreenHeight) {
            // Enqueue the CSS file for Galaxy Plus S9 screen size
            console.log('before generated link for Galaxy Plus S9');
            cssFilePath = screenSizeDataGalaxyPlusS9.themeDirURI + '/galaxy-plus-s9.css';
        } else if (screenWidth <= screenSizeDataDefault.minScreenWidth && screenHeight <= screenSizeDataDefault.minScreenHeight) {
            // Enqueue the CSS file for default screen size
            console.log('before generated link for default');
            cssFilePath = screenSizeDataDefault.themeDirURI + '/iphone-xs.css';
        } else if (screenWidth <= screenSizeDataKindleFireHdx.minScreenWidth && screenHeight <= screenSizeDataKindleFireHdx.minScreenHeight) {
            console.log('before generated link for Kindle Fire HDX');
            cssFilePath = screenSizeDataKindleFireHdx.themeDirURI + '/kindle-fire-hdx.css';
        } else if (screenWidth <= screenSizeDataMediumScreen.minScreenWidth && screenHeight <= screenSizeDataMediumScreen.minScreenHeight) {
            console.log('before generated link for medium screens');
            cssFilePath = screenSizeDataMediumScreen.themeDirURI + '/medium-screen.css';
        }

        if (cssFilePath) {
            console.log(cssFilePath);
            enqueueCSS(cssFilePath);
        }
    }

    // Function to enqueue CSS file
    function enqueueCSS(cssFilePath) {
        var linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.type = 'text/css';
        linkElement.href = cssFilePath;
        document.head.appendChild(linkElement);
    }

    // Call the function when the document is ready
    checkScreenSizeAndEnqueueCSS();
});
